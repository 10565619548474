<template>
    <section class="deuda-ver">
        <div class="row mx-0">
            <div class="col px-0">
                <!-- Info -->
                <div class="row mx-0 pr-3 pb-2 align-items-center">
                    <div class="btn-back" @click="$router.back();">
                        <i class="icon-left text-white f-12" />
                    </div>
                    <div class="col-auto d-middle">
                        <img :src="datos.logo_mini_firmado" width="35" height="35" class="obj-cover border rounded-circle ml-2" />
                        <p class="text-general f-17 f-600 ml-2">{{ datos.nombre }}</p>
                    </div>
                    <div class="btn-general px-3 ml-auto" @click="registrarPago()">
                        Registrar pago
                    </div>
                    <div class="btn-outline px-3 ml-3" @click="anular()">
                        Anular crédito
                    </div>
                </div>
                <div class="row mr-3 ml-0 bg-white br-8 py-2 align-items-center">
                    <div class="col-auto pr-2">
                        <i class="icon-pedidos f-20 text-general" />
                    </div>
                    <p class="col-auto pl-0 text-general f-15 f-600">
                        Pedido No. {{ datos.id_pedido }}
                    </p>
                    <p class="col-auto pl-0 mx-3 f-15 text-general">
                        <span class="mr-1">Fecha:</span>
                        <b> {{datos.created_at_pedido | helper-fecha('DD MMM Y hh:mm a')}}</b>
                    </p>
                    <p class="col-auto mx-3 pl-0 text-general">
                        Productos: <b>{{ datos.productos_count }}</b>
                    </p>
                    <div class="col-auto px-0 f-15 mr-2">
                        <router-link class="text-general" style="text-decoration:underline;" :to="{ name: 'admin.almacen.pedidos', params: { id_pedido: datos.id_pedido } }" target="_blank">
                            Ir al pedido
                        </router-link>
                    </div>
                </div>
                <div class="row mx-0 py-2" />
                <!-- Cuotas y historial -->
                <div class="row mr-3 ml-0 bg-white br-t-12">
                    <div class="col py-2 text-center border-bottom border-right text-general f-17">
                        Valor total del pedido: <b>{{ separadorNumero(datos.valor_final) }}</b>
                        <span class="ml-3">Cuotas: {{ datos.creditos && datos.creditos.length || 0 }}</span>
                    </div>
                    <div class="col py-2 text-center border-bottom border-left text-general">
                        <span class="mr-4">Pagó: <b>{{ separadorNumero(datos.total_pagado) }}</b></span>
                        <span class="mr-2">Debe: <b class="text-general-red">{{ separadorNumero(datos.total_debe) }}</b></span>
                        <span class="ml-3">Pagos: {{ datos.historial && datos.historial.length || 0 }}</span>
                    </div>
                </div>
                <div class="row mr-3 ml-0 bg-white">
                    <div class="col custom-scroll overflow-auto border-right pt-3" style="height:calc(100vh - 241px);">
                        <div class="row mx-0 pt-3 justify-center">
                            <div v-for="(cuota, c) in datos.creditos" :key="c" class="col-10 px-0 py-2 border br-8 mb-3">
                                <div class="row mx-0">
                                    <p class="col text-general f-16 f-600">{{ separadorNumero(cuota.valor) }}</p>
                                    <p class="col pl-0 text-right text-general f-15">
                                        <span v-text="textosCuotas(cuota)" />
                                        <i :class="`f-18 ${iconoCuotas(cuota)}`" />
                                    </p>
                                    <div class="col-12 text-general f-15 d-flex">
                                        {{ formatearFecha(cuota.fecha) }}
                                        <span v-if="isParcial(cuota)" class="text-right ml-auto"> {{ separadorNumero(cuota.valor_pendiente) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col custom-scroll overflow-auto border-left pt-3" style="height:calc(100vh - 241px);">
                        <div class="row mx-0 justify-center pt-3">
                            <div v-for="(historial, h) in datos.historial" :key="h" class="col-10 px-0 py-2 border br-8 mb-3 cr-pointer" @click="detallePago(historial)">
                                <div class="row mx-0">
                                    <div class="col-auto pr-1">
                                        <img :src="imagenHistorial(historial)" alt="" />
                                    </div>
                                    <div class="col px-0 pt-1">
                                        <div class="row mx-0">
                                            <p class="col text-general tres-puntos f-16 f-500">
                                                {{ separadorNumero(historial.valor) }}
                                            </p>
                                            <div class="col d-flex justify-content-end">
                                                <div :class="`px-3 br-20 ${historial.estado == 1 || historial.estado == 0 ? 'aprobado' : historial.estado == 2 ? 'pendiente' : 'cancelado'}`">
                                                    {{ historial.estado == 1 || historial.estado == 0 ? 'Aprobado' : historial.estado == 2 ? 'Pendiente' : 'Cancelado' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mx-0 my-2">
                                            <p class="col text-general2 f-15">
                                                {{ nombreHistorial(historial) }}
                                            </p>
                                            <p v-if="historial.ref" class="col text-right tres-puntos text-general">
                                                Ref: {{ historial.x_payus && historial.x_payus.transaction_id || historial.id }}
                                            </p>
                                        </div>
                                        <div class="row mx-0">
                                            <p class="col-12 text-general2 f-15">
                                                {{ formatearFecha(historial.created_at) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 bg-white">
                Aqui va el chat
            </div>
        </div>
        <modal-detalle-pago ref="modalDetallePago" />
        <modal-registrar-pago ref="modalRegistrarPago" @saved="detalleLeechero()" />
        <modal-anular-credito-tendero ref="modalAnularCreditoTendero" @actualizar="$router.back()" />
    </section>
</template>

<script>
import creditos from '~/services/creditos/creditos'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    components: {
        modalDetallePago: () => import('~/pages/almacen/tendero/partials/modalDetallePago.vue'),
        modalRegistrarPago: () => import('~/pages/tesoreria/partials/modalRegistrarPago.vue'),
        modalAnularCreditoTendero: () => import('~/pages/almacen/pedidos/partials/modalAnularCredito'),

    },
    data(){
        return{
            datos: {},
            loading: false
        }
    },
    computed:{
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },
    watch:{

        id_moneda(val, oldVal){
            if(oldVal == null) return
            this.detalleLeechero()
        }
    },
    async mounted(){
        await this.detalleLeechero()
    },
    methods: {
        registrarPago(){
            this.$refs.modalRegistrarPago.toggle(this.datos)
        },
        anular(){
            this.$refs.modalAnularCreditoTendero.toggle(this.datos.id_pedido)
        },
        imgHistorial(estado){
            switch (estado){
            case 1:
                return '/img/icons/pago_efectivo.svg';
            case 2:
                return '/img/icons/pago_online.svg';
            case 3:
                return '/img/icons/pago_online.svg';
            }
        },
        iconoCuotasEstablecidas(estado){
            switch (estado){
            case 1:
                return 'icon-ok-circled-outline text-success';
            case 2:
                return 'icon-alert-triangle text-warning';
            case 3:
                return 'icon-attention-alt text-general';
            case 4:
                return 'icon-attention-alt text-general-red';
            }
        },
        textosCuota(estado){
            switch (estado){
            case 1:
                return 'Pagada';
            case 2:
                return 'Pago parcial';
            case 3:
                return 'Cuota vencida';
            case 4:
                return 'Próxima cuota';
            }
        },
        iconoCuotas(cuota){
            if (cuota.valor_pendiente == 0){
                return this.iconoCuotasEstablecidas(1)
            }
            if(cuota.valor_pendiente == cuota.valor){
                if (moment(cuota.fecha).isBefore(moment().format('YYYY-MM-DD'))){
                    return this.iconoCuotasEstablecidas(3)

                } else {
                    return this.iconoCuotasEstablecidas(4)

                }
            }
            if(cuota.valor_pendiente != cuota.valor){
                return this.iconoCuotasEstablecidas(2)
            }

        },
        detallePago(pago){
            this.$refs.modalDetallePago.toggle(pago);
        },
        isParcial: (cuota) =>  cuota.valor_pendiente != 0 && (cuota.valor_pendiente != cuota.valor),
        textosCuotas(cuota){
            if (cuota.valor_pendiente == 0){
                return this.textosCuota(1)
            }
            if(cuota.valor_pendiente == cuota.valor){
                if (moment(cuota.fecha).isBefore(moment().format('YYYY-MM-DD'))){
                    return this.textosCuota(3)

                } else {
                    return this.textosCuota(4)

                }
            }
            if(cuota.valor_pendiente != cuota.valor){
                return this.textosCuota(2)
            }
        },
        async detalleLeechero(){
            try {
                this.loading = true
                const { id_pedido: pedido } = this.$route.params
                const params = {
                    pedido,
                    moneda: this.id_moneda
                }
                const { data } = await creditos.detalleLeechero(params)
                this.datos = data
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        imagenHistorial(historial){
            if(historial.estado == 0 && historial.tipo_efectivo == null) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 1) return '/img/icons/pago_efectivo.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 2) return '/img/icons/transferencia.svg';
            if(historial.estado != 0 && historial.tipo_efectivo == null) return '/img/icons/transferencia.svg';
            if(historial.estado == 0 && historial.tipo_efectivo == 3) return '/img/icons/pago_efectivo.svg';
            return '/img/icons/pago_online.svg';
        },
        nombreHistorial(historial){
            if(historial.estado == 0 && historial.tipo_efectivo == null) return 'Efectivo';
            if(historial.estado == 0 && historial.tipo_efectivo == 1) return 'Efectivo';
            if(historial.estado == 0 && historial.tipo_efectivo == 2) return 'Transferencia';
            if(historial.estado != 0 && historial.tipo_efectivo == null) return 'Transferencia';
            if(historial.estado == 0 && historial.tipo_efectivo == 3) return 'Por comisión'
            return 'En línea'
        },
    }
}
</script>
<style lang="css" scoped>
.aprobado{
    background-color: #27D07B;
    color: #ffffff;
}
.pendiente{
    background-color: #F5F5F5;
    color: #707070;
}
.cancelado{
    background-color: #FF3B63;
    color: #ffffff;
}
</style>
